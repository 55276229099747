<!-- sys-operation -->
<template>
  <div class="sys-operation">sys-operation</div>
</template>

<script>
  export default {
    name: 'sys-operation',
  };
</script>

<style scoped lang="scss">
  .sys-operation {
  }
</style>
